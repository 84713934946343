import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Api_url } from './helper';
import Header from './Header';



const AcceptedBooksTable = () => {
  
  const [acceptedBooks, setAcceptedBooks] = useState([]);

  useEffect(() => {
    const fetchAcceptedBooks = async () => {
      try {
        const response = await axios.get(`${Api_url}/sign/acceptedBooks`); // Adjust the API endpoint as necessary
        console.log(response.data.documents)
		  setAcceptedBooks(response.data.documents);
      } catch (error) {
        console.error('Error fetching accepted books:', error);
      }
    };

    fetchAcceptedBooks();
  }, []);

	return (<>
	  <Header/>
    <TableContainer component={Paper} style={{width:'90%',margin:'auto'}}>
      <Table  style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal' }}>
        <TableHead style={{ background: 'var(--Brand-Dark-Blue, #053F5C)',color:'#EFEFEF',fontSize:'12px'}}>
					<TableRow>
					<TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>index</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>User ID</TableCell>
					  <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>Book ID</TableCell>
					  <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>BookName</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>Book Review</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>Invoice</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>Reviews</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>User Name</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>User Email</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>Bank Account Holder Name</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>Bank Account Number</TableCell>
            <TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>IFSC Code</TableCell>
						<TableCell style={{ color: '#EFEFEF', fontSize: '12px' }}>PAN Card</TableCell>
						<TableCell style={{color:'#EFEFEF',fontSize:'12px'}}>Amazon Link</TableCell>
          </TableRow>
        </TableHead>
		<TableBody>
  {acceptedBooks.map((book,index) => (
	  <TableRow key={book._id}>
	      <TableCell style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000' }}>{index+1}</TableCell>	  
      <TableCell style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000' }}>{book.userId}</TableCell>
      <TableCell style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000' }}>{book.bookId}</TableCell>
      <TableCell style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000' }}>{book.bookname}</TableCell>
      <TableCell style={{ fontSize: '12px',minWidth: '900px', maxWidth: '900px', whiteSpace: 'normal', overflow: 'hidden',border:'1px solid #000000' }}>{book.bookreview}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000', fontSize: '12px' }}>{book.invoiceb}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000', fontSize: '12px' }}>{book.Reviewsc}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000', fontSize: '12px' }}>{book.userName}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000', fontSize: '12px' }}>{book.userEmail}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000', fontSize: '12px' }}>{book.bankDetails.bankAccountHolderName}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000', fontSize: '12px' }}>{book.bankDetails.bankAccountNumber}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden',border:'1px solid #000000', fontSize: '12px' }}>{book.bankDetails.ifscCode}</TableCell>
		  <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', border: '1px solid #000000', fontSize: '12px' }}>{book.bankDetails.PanCard}</TableCell>
		
			  <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', border: '1px solid #000000', fontSize: '12px' }}>  <a href={book.amazonLink} target="_blank" rel="noopener noreferrer">{book.amazonLink}</a></TableCell> 
    </TableRow>
  ))}
</TableBody>

      </Table>
	  </TableContainer>
	  </>
  );
};

export default AcceptedBooksTable;

import React, { useState } from 'react'
import Header from './Header'
import { TextField,Grid, Typography,Button, Select, MenuItem, FormControl, InputLabel} from '@mui/material'
import axios from 'axios';
import { Api_url } from './helper';

function Update() {
	const [bookname, setBookname] = useState(''); 
	const [amazonlink, setAmazonlink] = useState('');
	const [review, setReview] = useState('');
	const [image, setImage] = useState(null);
	const [bookType, setBookType] = useState('Paperback'); // Default value
	const [imageFile, setImageFile] = useState(null);
// Handle changes from the dropdown
const handleBookTypeChange = (event) => {
    setBookType(event.target.value);
	};
	const handleFileChange = (e) => {
		setImageFile(e.target.files[0]);
	  };
	const handleSubmit = async() => {
		const response = await axios.post(`${Api_url}/sign/update/${bookname}`);
		console.log(response.data)
		setImage(response.data.image)
	}
	const handleUpdate = async () => {
		try {
		  // Create a FormData object to hold the form fields and the image file
		  const formData = new FormData();
		  
		  // Append the form fields to FormData
		  formData.append('bookname', bookname);
		  formData.append('review', review);
		  formData.append('amazonlink', amazonlink);
		  formData.append('bookType', bookType);
		  
		  // If an image file has been selected, append it to FormData
		  if (imageFile) {
			formData.append('imageFile', imageFile);
		  }
	  
		  // Make a POST request with the form data (multipart/form-data)
		  const response = await axios.post(`${Api_url}/sign/updated`, formData, {
			headers: {
			  'Content-Type': 'multipart/form-data', // Ensure the request is sent as multipart/form-data
			},
		  });
		  
		  // Handle the response (e.g., display a success message)
		  console.log(response.data);
	  
		  // Clear the form fields after successful submission
		  setBookname('');
		  setReview('');
		  setAmazonlink('');
		  setBookType('');
		  setImageFile(null); // Clear the image file state
		} catch (error) {
		  console.error('Error updating:', error);
		}
	  };
	  
	return (
	  
	  <div>
		  <Header />
		  <Grid container lg={12} sx={{background:'#EFEFEF',minHeight:'90vh'}}>
				<Grid container lg={10} sx={{ margin: 'auto',display:'flex', }}>
					<Grid container lg={6} sx={{justifyContent:'center'}}>
					<Grid item lg={7}>
					<Typography>BookId</Typography>
					</Grid>
					<Grid item lg={7}>
					<TextField value={bookname} onChange={(e) => setBookname(e.target.value)}  variant="outlined"
                    fullWidth
                    InputProps={{
                      size: 'small',
                      style: { marginTop: '8px', borderRadius: '6px', backgroundColor: '#Fff', border: '1px solid #A3A2A2' }
                    }}
                    InputLabelProps={{
                      style: { color: '#787373', fontSize: '14px', fontWeight: '400', fontFamily: 'Space Grotesk' }
                    }} />
					</Grid>
					<Grid item lg={7}>
					<Button onClick={handleSubmit}        style={{ marginBottom: '76px', marginTop: '36px', fontSize: '18px', fontWeight: '700px', lineHeight: '32px', color: '#fff', backgroundColor: '#005B9D', textTransform: 'none', fontFamily: 'Space Grotesk' }}
             >Submit </Button>
					</Grid>
					</Grid>
					
				  
				
					
				</Grid>
				<Grid container lg={12}>
					{image && <>
						<Grid container lg={6} sx={{display:'flex',justifyContent:'center'}}>
							<img src={image} style={{width:'450px',height:'auto'}} />
						</Grid> <form onSubmit={handleSubmit}>
						<Grid container lg={6}>
							<Grid item lg={12}>
							<Typography sx={{ fontSize: '56px', fontWeight: '700', color: '#005B9D', fontFamily: 'Space Grotesk', marginTop: '76px' }}>Update Form</Typography>	
							</Grid>
							<Grid item lg={10}>
							<Typography>Amazon</Typography>
								<TextField value={amazonlink} onChange={(e) => setAmazonlink(e.target.value)} placeholder='Provide your link here'
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      size: 'small',
                      style: { marginTop: '8px', borderRadius: '6px', backgroundColor: '#Fff', border: '1px solid #A3A2A2' }
                    }}
                    InputLabelProps={{
                      style: { color: '#787373', fontSize: '14px', fontWeight: '400', fontFamily: 'Space Grotesk' }
                    }} />
							</Grid>
							<Grid item lg={10}>
						<Typography>BookReviewCount</Typography>
						<TextField value={review} onChange={(e) => setReview(e.target.value)} variant="outlined"
                    fullWidth
                    InputProps={{
                      size: 'small',
                      style: { marginTop: '8px', borderRadius: '6px', backgroundColor: '#Fff', border: '1px solid #A3A2A2' }
                    }}
                    InputLabelProps={{
                      style: { color: '#787373', fontSize: '14px', fontWeight: '400', fontFamily: 'Space Grotesk' }
                    }} />
							</Grid>
							<Grid item lg={10}>
    <Typography>Book Type</Typography>
    <FormControl fullWidth variant="outlined" sx={{ marginTop: '8px' }}>
        <InputLabel
            style={{ color: '#787373', fontSize: '14px', fontWeight: '400', fontFamily: 'Space Grotesk' }}
        >
            Select Book Type
        </InputLabel>
        <Select
            value={bookType} // Your state for the selected value
            onChange={(e) => setBookType(e.target.value)} // Handle change
            label="Select Book Type"
            fullWidth
            variant="outlined"
            sx={{
                backgroundColor: '#Fff',
                border: '1px solid #A3A2A2',
                borderRadius: '6px',
                '& .MuiOutlinedInput-input': {
                    padding: '10.5px 14px',
                },
            }}
            size="small"
        >
            <MenuItem value="Paperback">Paperback</MenuItem>
            <MenuItem value="kindle">Kindle</MenuItem>
        </Select>
    </FormControl>
								</Grid>
								<div>
          <label>Upload Image:</label>
          <input type="file" onChange={handleFileChange} />
        </div>
							<Grid item lg={12}>
								<Button onClick={handleUpdate}        style={{ marginBottom: '76px', marginTop: '36px', fontSize: '18px', fontWeight: '700px', lineHeight: '32px', color: '#fff', backgroundColor: '#005B9D', textTransform: 'none', fontFamily: 'Space Grotesk' }}
             >Submit </Button>
								</Grid>
						
						
						</Grid>
						</form>
					</>}
				</Grid>
		  </Grid>
		  </div>
  )
}

export default Update
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Typography, Button, TablePagination, fabClasses } from '@mui/material';
import Header from './Header';
import { Api_url } from './helper';

function Request() {
  const [requests, setRequests] = useState([]);
	const [accepted1, setaccepted] = useState(false);
	const [rejected, setrejected] = useState(false);
	
  useEffect(() => {
    fetchData();
  }, [accepted1,rejected]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Api_url}/sign/upload3`);
      setRequests(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

	const handleAccept = async (bookId, userId) => {
	  console.log(bookId,'kjh')
    try {
      const response = await axios.put(`${Api_url}/sign/acceptBook`, { userId, bookId });
		console.log(response.data);
		setaccepted(!accepted1);
      // You can also update the UI to reflect the accepted book
    } catch (error) {
      console.error('Error accepting book:', error);
    }
  };

  const handleReject = async (bookId, userId) => {
    try {
      const response = await axios.put(`${Api_url}/sign/rejectBook`, { userId, bookId });
		console.log(response.data);
		setrejected(!rejected);
      // You can also update the UI to reflect the rejected book
    } catch (error) {
      console.error('Error rejecting book:', error);
    }
  };
  const handleClick = (invoice) => {
    // Handle the click event
    console.log("PDF File Requested: ", invoice);
    window.open(invoice,'_blank')
    // Other logic to display PDF
}


  

  return (
	  <>
		  <Header/>
      <Grid container lg={12} sx={{ backgroundColor: '#EFEFEF' }}>
        <Grid container lg={10} sx={{ margin: 'auto',justifyContent:'center' }}>
        <Grid item lg={12}>
            <Typography sx={{ color: '#292611', marginTop: '76px', marginBottom: '36px', fontSize: '40px', fontWeight: '700', fontFamily: 'Space Grotesk' }}>Requests</Typography>
          </Grid>
          <Grid container lg={10} sx={{borderRadius:'40px',padding:'32px'}}>
         
          {requests.filter((request) => request.status === 'pending').map((request, index) => (request.status === 'pending' && (
            <Grid container key={request.id} lg={12} style={{ display: "flex",backgroundColor:'#fff',marginTop:"20px",marginBottom:"20px",borderRadius:'40px',justifyContent:'center' }}>
				  <Grid item lg={12} sx={{backgroundColor:"#053F5C",height:"83px",borderRadius:' 40px 40px 0px 0px',justifyContent:'center',display:'flex'}}>
					  <Grid item lg={11} >
						  
					  <Typography style={{ fontSize: "24px", color: '#EFEFEF', lineHeight: '36px', marginTop: '20px', marginLeft: '0px' }}>{request.userName}
						  </Typography> 
						  </Grid>
					  </Grid>
					  <Grid item lg={11} sx={{justifyContent:'center',margin:'auto',display:'flex'}}>
					  <Grid item lg={9}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }} >
                Book Name: 
					  </Typography>
					  
					  </Grid>
					  <Grid item lg={3}>
					  <Typography >
                 {request.bookname}
					  </Typography>
						  </Grid>
					  </Grid>
					  <Grid item lg={11} sx={{justifyContent:'center',margin:'auto',display:'flex'}}>
					  <Grid item lg={4}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }} >
                Amazon Link: 
					  </Typography>
					  
					  </Grid>
					  <Grid item lg={8}>
					
  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', wordWrap: 'break-word' }}>
    {request.amazonLink}
  </Typography>

						  </Grid>
						  </Grid>
						  <Grid item lg={11} sx={{justifyContent:'center',margin:'auto',display:'flex'}}>
					  <Grid item lg={9}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }} >
            Email: 
					  </Typography>
					  
					  </Grid>
					  <Grid item lg={3}>
					  <Typography >
					  {request.userEmail}
					  </Typography>
						  </Grid>
						  </Grid>
					  
					 
				  <Grid item lg={11} style={{display:'flex',justifyContent:'space-between'}}>
					  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
					  User ID: 
					  </Typography>
				  <Typography >
                {request.userId}
              </Typography>
				  </Grid>
				  <Grid item lg={11} sx={{justifyContent:'center',margin:'auto',display:'flex'}}>
					  <Grid item lg={4}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }} >
				  bookReview 
					  </Typography>
					  
					  </Grid>
					  <Grid item lg={8}>
					  <Typography >
					  {request.bookreview}
					  </Typography>
						  </Grid>
						  </Grid>
				  <Grid item lg={11} style={{display:'flex',justifyContent:'space-between'}}>
					  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
					
					  </Typography>
				  <Typography >
				  
              </Typography>
				  </Grid>

				  <Grid item lg={11} style={{display:'flex',justifyContent:'space-between'}}>
					
				  </Grid>
				  <Grid item lg={11} style={{display:'flex',justifyContent:'space-between'}}>
				  <Typography  style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
              Invoice
              </Typography>
              <Button sx={{width:'150px',padding:'14px 24px',height:'24px',textTransform:'none',color:'#053F5C',border:'2px solid #053F5C',}} onClick={(e)=>handleClick(request.invoiceb)}>View Invoice</Button>
				  </Grid>
				  <Grid item lg={11} style={{ display: 'flex', justifyContent: 'space-between' }}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
                Screenshot
              </Typography>
              <img src={request.Reviewsc} style={{width:'260px',height:'auto'}}></img>
				  </Grid>
				  <Grid item  lg={11} style={{ display: 'flex', justifyContent: 'space-between' }}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
				  Status of Request
					  </Typography>
				  <Typography >
				  {request.status}
					  </Typography>
					  
					 
					  
				  </Grid>
				  
				  <Grid item  lg={11} style={{ display: 'flex', justifyContent: 'space-between' }}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
				  bankAccountHolderName
					  </Typography>
				  <Typography >
				  {request.bankDetails.bankAccountHolderName}
					  </Typography>
					  
					 
					  
					  </Grid>

				  <Grid item  lg={11} style={{ display: 'flex', justifyContent: 'space-between' }}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
					  bankAccountNumber
					  </Typography>
					  <Typography >
				  {request.bankDetails.bankAccountNumber}
					  </Typography>
					  </Grid>
             
				  <Grid item  lg={11} style={{ display: 'flex', justifyContent: 'space-between' }}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
					  IfscCode
					  </Typography>
					  <Typography >
				  {request.bankDetails.ifscCode}
					  </Typography>
				  </Grid>
				  <Grid item  lg={11} style={{ display: 'flex', justifyContent: 'space-between' }}>
				  <Typography style={{ color: 'var(--Dark, #292611)', fontFamily: 'Space Grotesk', fontSize: '20px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal' }}>
					  PanCard
					  </Typography>
					  <Typography >
				  {request.bankDetails.PanCard}
              </Typography>
					  </Grid>
            
             
              <Grid item lg={11} style={{ display: "flex", justifyContent: "end",marginBottom:"20px" }}>
                <Button sx={{ backgroundColor: '#F20C0C', color: '#fff', fontSize: '14px', fontWeight: '30px', padding: '14px 30px', borderRadius: '6px', height: '42px', textTransform: 'none', '&:hover': { backgroundColor: '#F20C0C' } }} onClick={()=>handleAccept(request.bookId, request.userId)}>Accept</Button>
                <Button sx={{ backgroundColor: '#00A58E', color: '#fff', fontSize: '14px', fontWeight: '30px', padding: '14px 30px', borderRadius: '6px', height: '42px', textTransform: 'none', '&:hover': { backgroundColor: '#00A58E' } }} onClick={()=>handleReject(request.bookId, request.userId)}>Reject</Button>
              </Grid>
            </Grid>
           
          )))}
           </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Request;